import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { Card, Table, Button, Modal, Form, Alert, Spinner } from "react-bootstrap";
import "./Orders.css";

// Helper functions
const formatTimestamp = (timestamp) => {
  if (timestamp && timestamp._seconds) {
    const date = new Date(timestamp._seconds * 1000);
    return date.toLocaleDateString();
  }
  return '';
};

const formatPrice = (price) => {
  return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

const Orders = () => {
  const { currentUser } = useAuth();
  const [orders, setOrders] = useState([]);
  const [printers, setPrinters] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedPrinter, setSelectedPrinter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [originalOrders, setOriginalOrders] = useState([]);
  const [showAlert, setShowAlert] = useState({ show: false, message: '', variant: '' });
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [printInProgress, setPrintInProgress] = useState(false);

  // Auto-dismiss alert after 5 seconds
  useEffect(() => {
    if (showAlert.show) {
      const timer = setTimeout(() => {
        setShowAlert({ show: false, message: '', variant: '' });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert.show]);

  const fetchOrdersAndPrinters = useCallback(async () => {
    if (!currentUser) return { orders: [], printers: [] };
    const token = currentUser.stsTokenManager.accessToken;

    try {
      // Use Promise.all to fetch orders and printers concurrently
      const [orderResponse, printerResponse] = await Promise.all([
        axios.get(
          `${process.env.REACT_APP_SERVER_URL}/pairbo/order/print-client-orders?t=${Date.now()}`,
          { headers: { Authorization: `Bearer ${token}` } }
        ),
        axios.get(
          `${process.env.REACT_APP_SERVER_URL}/pairbo/printer/printer-details?t=${Date.now()}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
      ]);

      let fetchedOrders = orderResponse.data;
      // Sort orders with the most recent first
      fetchedOrders = fetchedOrders.sort((a, b) => b.orderCreatedAt._seconds - a.orderCreatedAt._seconds);
      const fetchedPrinters = printerResponse.data;

      setOriginalOrders(fetchedOrders);
      setOrders(fetchedOrders);
      setPrinters(fetchedPrinters);

      return { orders: fetchedOrders, printers: fetchedPrinters };
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchOrdersAndPrinters();
      const intervalId = setInterval(fetchOrdersAndPrinters, 3600000); // Refresh every hour
      return () => clearInterval(intervalId);
    }
  }, [currentUser, fetchOrdersAndPrinters]);

  const handlePrint = (orderId) => {
    if (printInProgress) return;
    setSelectedOrder(orderId);
    setShowModal(true);
  };

  const handleSelectPrinter = (printerId) => {
    setSelectedPrinter(printerId);
  };

  const handleConfirmPrint = async () => {
    if (!selectedPrinter || printInProgress) return;
    setPrintInProgress(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/pairbo/cards/print`,
        {
          orderId: String(selectedOrder),
          printerId: String(selectedPrinter),
        },
        {
          headers: { Authorization: `Bearer ${currentUser.stsTokenManager.accessToken}` },
        }
      );
      setShowAlert({ show: true, message: 'Print request sent successfully!', variant: 'success' });
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed to send print request. Please try again.', variant: 'danger' });
      console.error("Error sending print request:", error);
    } finally {
      setPrintInProgress(false);
      setShowModal(false);
      setTimeout(() => {
        setShowAlert({ show: false, message: '', variant: '' });
      }, 4000);
    }
  };

  // Update filtering as user types without a search button
  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const trimmedQuery = query.trim().toLowerCase();
    if (trimmedQuery === '') {
      setOrders(originalOrders);
    } else {
      const filteredOrders = originalOrders.filter(order => {
        const orderIdStr = String(order.orderId).toLowerCase();
        const orderNumberStr = String(order.orderNumber).toLowerCase();
        return orderIdStr.includes(trimmedQuery) || orderNumberStr.includes(trimmedQuery);
      });
      setOrders(filteredOrders);
    }
  };

  const handleRefresh = async () => {
    setRefreshLoading(true);
    try {
      const { orders: newOrders } = await fetchOrdersAndPrinters();
      const trimmedQuery = searchQuery.trim().toLowerCase();
      if (trimmedQuery) {
        const filteredOrders = newOrders.filter(order => {
          const orderIdStr = String(order.orderId).toLowerCase();
          const orderNumberStr = String(order.orderNumber).toLowerCase();
          return orderIdStr.includes(trimmedQuery) || orderNumberStr.includes(trimmedQuery);
        });
        setOrders(filteredOrders);
      }
      setShowAlert({ show: true, message: 'Orders refreshed successfully!', variant: 'success' });
      setTimeout(() => setShowAlert({ show: false, message: '', variant: '' }), 2000);
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed to refresh orders. Please try again.', variant: 'danger' });
      console.error("Error refreshing orders:", error);
    } finally {
      setRefreshLoading(false);
    }
  };

    // Process the scanned barcode
    const processBarcode = (barcode) => {
      // Assume the barcode represents the Order ID.
      const order = orders.find(o => String(o.orderId) === barcode);
      if (order) {
        // If a printer is not yet selected, use the first available printer as default.
        const defaultPrinter = printers.length > 0 ? printers[0].id : null;
        if (!defaultPrinter) {
          setShowAlert({ show: true, message: 'No printers available.', variant: 'danger' });
          return;
        }
        // Show a processing alert
        setShowAlert({ show: true, message: 'Print request is processing...', variant: 'warning' });
        // Send the print request automatically.
        axios.post(
          `${process.env.REACT_APP_SERVER_URL}/pairbo/cards/print`,
          {
            orderId: String(order.orderId),
            printerId: String(defaultPrinter),
          },
          {
            headers: { Authorization: `Bearer ${currentUser.stsTokenManager.accessToken}` },
          }
        )
          .then(() => {
            setShowAlert({ show: true, message: `Print request for order ID ${barcode} sent successfully!`, variant: 'success' });
          })
          .catch((error) => {
            setShowAlert({ show: true, message: 'Failed to send print request. Please try again.', variant: 'danger' });
            console.error("Error sending print request:", error);
          });
      } else {
        setShowAlert({ show: true, message: `Order ID ${barcode} not found.`, variant: 'danger' });
      }
    };

  // --- Barcode Scanner Integration ---
  useEffect(() => {
    let barcodeBuffer = "";
    let bufferTimeout;

    const handleKeyDown = (e) => {
      // Check if the key is TAB which indicates the end of a scan.
      if (e.key === "Tab") {
        e.preventDefault(); // Prevent default behavior like changing focus.
        if (barcodeBuffer.length > 0) {
          processBarcode(barcodeBuffer);
          barcodeBuffer = "";
        }
      } else {
        // Append the key to the buffer.
        barcodeBuffer += e.key;
        // Reset the buffer if keys stop coming in quickly (adjust timing as needed).
        if (bufferTimeout) clearTimeout(bufferTimeout);
        bufferTimeout = setTimeout(() => {
          barcodeBuffer = "";
        }, 100);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      if (bufferTimeout) clearTimeout(bufferTimeout);
    };
  }, [orders, printers, currentUser]);


  
  // --- End Barcode Scanner Integration ---

  return (
    <div className="container mt-5 pt-4">
      <div className="mb-4 d-flex">
        <Form.Control
          type="text"
          placeholder="Search by Order # or Order ID"
          value={searchQuery}
          onChange={handleSearchInputChange}
          className="search-input"
        />
        <Button variant="secondary" onClick={handleRefresh} className="ml-2" disabled={refreshLoading}>
          {refreshLoading ? (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
          ) : 'Refresh'}
        </Button>
      </div>

      <Alert
        show={showAlert.show}
        variant={showAlert.variant}
        onClose={() => setShowAlert({ show: false })}
        dismissible
      >
        {showAlert.message}
      </Alert>

      <Card className="mb-4 shadow-sm" bg="dark" text="white">
        <Card.Header as="h3" className="text-center bg-dark text-white">
          Orders
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive className="table-custom text-white">
            <colgroup>
              <col style={{ width: '10%' }} />
              <col style={{ width: '25%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '25%' }} />
            </colgroup>
            <thead className="thead-dark">
              <tr>
                <th className="text-center">Order #</th>
                <th className="text-center order-id-header">Order ID</th>
                <th className="text-center">Date Created</th>
                <th className="text-center">Order Total</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {orders.length > 0 ? (
                orders.map((order, index) => (
                  <tr key={index}>
                    <td className="text-center">{order.orderNumber}</td>
                    <td className="text-center order-id">{order.orderId}</td>
                    <td className="text-center">{formatTimestamp(order.orderCreatedAt)}</td>
                    <td className="text-center">
                      <div className="price-wrapper">${formatPrice(order.totalPrice)}</div>
                    </td>
                    <td className="text-center">
                      <Button
                        className="action-btn btn btn-primary"
                        onClick={() => handlePrint(order.orderId)}
                        disabled={printInProgress}
                      >
                        Print
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">No orders found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Printer Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formPrinterSelect">
            <Form.Label>Choose Printer Location</Form.Label>
            <div className="d-flex flex-wrap">
              {printers.map(printer => (
                <Button
                  key={printer.id}
                  variant={selectedPrinter === printer.id ? "primary" : "outline-primary"}
                  className="m-2 location-btn"
                  onClick={() => handleSelectPrinter(printer.id)}
                  disabled={printInProgress}
                >
                  {printer.location}
                </Button>
              ))}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={() => setShowModal(false)} className="modal-btn">
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmPrint}
            disabled={!selectedPrinter || printInProgress}
            className="modal-btn"
          >
            {printInProgress ? (
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : "Print"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Orders;
